import { adNotam } from '../../init';

adNotam.website.formWithSummary = {
  init: function () {
    // remove honeypot field (against SPAM)
    const e = document.getElementById('nickname');
    e.parentNode.removeChild(e);

    $('#formWithSummary').validate({
      rules: {
        'user[password]': {
          minlength: 8
        },
        'user[password_confirmation]': {
          equalTo: '#user_password'
        },
        'message[project_units]': {
          min: 1
        }
      },
      onfocusout: false,
      invalidHandler: function (form, validator) {
        let errors = validator.numberOfInvalids();

        if (errors) {
          $(window).scrollTop($(validator.errorList[0].element).offset().top - 56);
          validator.errorList[0].element.focus();
        }
      },
      groups: {
        birthdate:
          'user[contact_attributes][birthdate(1i)] user[contact_attributes][birthdate(2i)] user[contact_attributes][birthdate(3i)]',
        project_date: 'message[project_day] message[project_month] message[project_year]'
      },
      errorPlacement: function (error, element) {
        if (
          element.attr('name') == 'message[salutation]' ||
          element.attr('name') == 'message[trained_personnel]' ||
          element.attr('name') == 'message[installation_experience]' ||
          element.attr('name') == 'message[own_installation_team]'
        ) {
          error.insertAfter(element.parent().parent().parent());
        } else if (
          element.attr('name') == 'user[contact_attributes][birthdate(1i)]' ||
          element.attr('name') == 'user[contact_attributes][birthdate(2i)]' ||
          element.attr('name') == 'user[contact_attributes][birthdate(3i)]'
        ) {
          error.insertAfter($('#regYear').parent().parent().parent());
        } else if (
          element.attr('name') == 'message[project_day]' ||
          element.attr('name') == 'message[project_month]' ||
          element.attr('name') == 'message[project_year]'
        ) {
          error.insertAfter($('#regPrjYear').parent().parent().parent());
        } else if (
          element.attr('name') == 'user[contact_attributes][salutation]' ||
          element.attr('name') == 'user[contact_attributes][is_work_address]' ||
          element.attr('name') == 'user[language]' ||
          element.attr('name') == 'user[contact_attributes][industry_id]' ||
          element.attr('name') == 'user[contact_attributes][cust_type_id]' ||
          element.attr('name') == 'user[contact_attributes][country_id]'
        ) {
          error.insertAfter(element.parent());
        } else if (
          $.inArray(element.attr('name'), [
            'message[country_id]',
            'message[industry_id]',
            'message[cust_type_id]',
            'message[project_country]',
            'message[project_contact_country]',
            'message[product_interest]',
            'message[product]',
            'message[series]',
            'message[model]',
            'message[application]',
            'message[product_line]'
          ]) !== -1
        ) {
          error.insertAfter(element.parent());
        } else if (
          element.attr('name') == 'message[gdpr_accepted]' ||
          element.attr('name') == 'user[contact_attributes][gdpr_accepted]' ||
          element.attr('name') == 'message[software_accepted]' ||
          element.attr('name') == 'user[contact_attributes][software_accepted]'
        ) {
          error.insertAfter(element.parent().parent());
        } else {
          error.insertAfter(element);
        }
      },
      highlight: function (element) {
        if (
          $(element).attr('name') == 'user[language]' ||
          $(element).attr('name') == 'user[contact_attributes][industry_id]' ||
          $(element).attr('name') == 'user[contact_attributes][cust_type_id]' ||
          $(element).attr('name') == 'user[contact_attributes][country_id]'
        ) {
          $(element).parent().addClass('error');
        } else if (
          $(element).attr('name') == 'user[contact_attributes][is_work_address]' ||
          $(element).attr('name') == 'user[contact_attributes][salutation]' ||
          $(element).attr('name') == 'message[salutation]' ||
          $(element).attr('name') == 'message[trained_personnel]' ||
          $(element).attr('name') == 'message[installation_experience]' ||
          $(element).attr('name') == 'message[own_installation_team]'
        ) {
          $(element).parent().addClass('error');
          $(element).parent().parent().next().children().first().addClass('error');
        } else if (
          $(element).attr('name') == 'user[contact_attributes][birthdate(1i)]' ||
          $(element).attr('name') == 'user[contact_attributes][birthdate(2i)]' ||
          $(element).attr('name') == 'user[contact_attributes][birthdate(3i)]'
        ) {
          $(element).parent().addClass('error');
          $(element).parent().parent().parent().addClass('error');
        } else if (
          $(element).attr('name') == 'message[project_day]' ||
          $(element).attr('name') == 'message[project_month]' ||
          $(element).attr('name') == 'message[project_year]'
        ) {
          $(element).parent().addClass('error');
        } else if (
          $.inArray($(element).attr('name'), [
            'message[country_id]',
            'message[industry_id]',
            'message[cust_type_id]',
            'message[project_country]',
            'message[project_contact_country]',
            'message[product_interest]',
            'message[product]',
            'message[series]',
            'message[model]',
            'message[gdpr_accepted]',
            'user[contact_attributes][gdpr_accepted]',
            'message[software_accepted]',
            'user[contact_attributes][software_accepted]',
            'message[application]',
            'message[product_line]'
          ]) !== -1
        ) {
          $(element).parent().addClass('error');
        } else {
          $(element).addClass('error');
        }
      },
      unhighlight: function (element) {
        if (
          $(element).attr('name') == 'user[language]' ||
          $(element).attr('name') == 'user[contact_attributes][industry_id]' ||
          $(element).attr('name') == 'user[contact_attributes][cust_type_id]' ||
          $(element).attr('name') == 'user[contact_attributes][country_id]'
        ) {
          $(element).parent().removeClass('error');
        } else if (
          $(element).attr('name') == 'user[contact_attributes][is_work_address]' ||
          $(element).attr('name') == 'user[contact_attributes][salutation]' ||
          $(element).attr('name') == 'message[salutation]' ||
          $(element).attr('name') == 'message[trained_personnel]' ||
          $(element).attr('name') == 'message[installation_experience]' ||
          $(element).attr('name') == 'message[own_installation_team]'
        ) {
          $(element).parent().removeClass('error');
          $(element).parent().parent().next().children().first().removeClass('error');
        } else if (
          $(element).attr('name') == 'user[contact_attributes][birthdate(1i)]' ||
          $(element).attr('name') == 'user[contact_attributes][birthdate(2i)]' ||
          $(element).attr('name') == 'user[contact_attributes][birthdate(3i)]'
        ) {
          $(element).parent().removeClass('error');
          $(element).parent().parent().parent().removeClass('error');
        } else if (
          $(element).attr('name') == 'message[project_day]' ||
          $(element).attr('name') == 'message[project_month]' ||
          $(element).attr('name') == 'message[project_year]'
        ) {
          $(element).parent().removeClass('error');
        } else if (
          $.inArray($(element).attr('name'), [
            'message[country_id]',
            'message[industry_id]',
            'message[cust_type_id]',
            'message[project_country]',
            'message[project_contact_country]',
            'message[product_interest]',
            'message[product]',
            'message[series]',
            'message[model]',
            'message[gdpr_accepted]',
            'user[contact_attributes][gdpr_accepted]',
            'message[software_accepted]',
            'user[contact_attributes][software_accepted]',
            'message[application]',
            'message[product_line]'
          ]) !== -1
        ) {
          $(element).parent().removeClass('error');
        } else {
          $(element).removeClass('error');
        }
      }
    });

    function setWorkAddress(bool) {
      if (bool == 'true' || bool === undefined) {
        $('.user_contact_industry_id').show();
        $('.user_contact_cust_type_id').show();
        $('.user_contact_company_name').show();
        $('.user_contact_position').show();
        $('.user_contact_phone_no').show();
        $('.user_contact_work_phone_no_direct').show();
        $('.user_contact_home_phone_no').hide();
        $('.user_contact_tin').show();
        $('.user_contact_vat').show();
        $('#businessDescription').show();
      } else {
        $('.user_contact_industry_id').hide();
        $('.user_contact_cust_type_id').hide();
        $('.user_contact_company_name').hide();
        $('.user_contact_position').hide();
        $('.user_contact_phone_no').hide();
        $('.user_contact_work_phone_no_direct').hide();
        $('.user_contact_home_phone_no').show();
        $('.user_contact_tin').hide();
        $('.user_contact_vat').hide();
        $('#businessDescription').hide();
      }
    }

    $('input[name="user[contact_attributes][is_work_address]"]').change(function () {
      setWorkAddress(this.value);
    });

    // ********************* Buttons behaviour *****************
    $('.formInput .btn-next-js').click(function () {
      if ($('#formWithSummary').valid()) {
        fillSummary();
        $(this).parents('.formInput').hide();
        $(this).parents('form').find('.formSummary').show();
      }
    });
    $('.formInput .btn-back').click(function () {
      window.history.back();
    });
    $('.formSummary .btn-back').click(function () {
      $(this).parents('.formSummary').hide();
      $(this).parents('form').find('.formInput').show();
    });
    // *********************************************************

    // ************ Passing content to summary *****************
    function fillSummary() {
      $('.formInput input, .formInput select, .formInput textarea').each(function () {
        let inputText, elementTarget;
        let target = $(this).attr('id');

        if ($(this).find('option:selected').is(':disabled')) {
          inputText = '';
        } else if ($(this).find('option:selected').length > 0) {
          inputText = $(this).find('option:selected').text();
        } else {
          inputText = $(this).val();
        }
        let spanTarget = $(this)
          .parents('form')
          .find('.formSummary span.' + target);
        let spanParent = $(spanTarget).parent();

        if (spanParent.prop('tagName') == 'P') {
          // If it isn't a p element it is a td and the target element is in this case its parent tr
          elementTarget = spanParent;
        } else {
          elementTarget = $(spanParent).parent();
        }
        spanTarget.text(inputText);

        let spansInelementTarget = $(elementTarget).find('span');
        let showP = false;

        $(spansInelementTarget).each(function () {
          if ($(this).text() !== '') {
            showP = true;
          }
        });
        if (showP) {
          elementTarget.show();
        } else {
          elementTarget.hide();
        }
      });
      if ($('input[name="user[contact_attributes][is_work_address]"]:checked').val() == 'true') {
        $('.businessData').each(function () {
          if ($(this).children('span').text() != '') {
            $(this).show();
          }
        });
        $('.privateData').hide();
      } else {
        $('.businessData').hide();
        $('.privateData').show();
      }
    }

    setWorkAddress($('input[name="user[contact_attributes][is_work_address]"]:checked').val());
  }
};

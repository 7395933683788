import { adNotam } from '../../init';
import ahoy from 'ahoy.js';
import iconSprite from 'mediaelement/build/mejs-controls.svg';

adNotam.website.movies = {
  init: function () {
    $('#mainVideo').mediaelementplayer({
      defaultVideoHeight: 567,
      defaultVideoWidth: 1260,
      iconSprite: iconSprite
    });
    let playerId = $('#mainVideo').closest('.mejs__container').attr('id');
    let myPlayer = mejs.players[playerId];

    $('.visualBackground video').removeAttr('controls');
    $('#mainVideo video').attr('poster', $('video').eq(1).attr('poster'));
    myPlayer.setSrc($('video').eq(1).find('source').attr('src'));
    $('#mainVideo').attr('data-id', $('video').eq(1).data('id'));

    $('#mainVideo').on('play', function () {
      ahoy.track('alchemy/attachments', {
        action: 'play',
        label: window.location.pathname,
        value: $('#mainVideo').attr('data-id')
      });
    });

    $('body[data-layout="movies"] .piclink').on('click', function (e) {
      e.preventDefault();
      myPlayer.setSrc($(this).find('source').attr('src'));
      if ($(this).find('video').attr('poster')) {
        $('#mainVideo video').attr('poster', $(this).find('video').attr('poster'));
      } else {
        $('#mainVideo video').removeAttr('poster');
      }
      $('#mainVideo').attr('data-id', $(this).find('video').data('id'));
      myPlayer.play();
    });
  }
};
